// Text Colors
$white: #ffffff;
$white-grey: #E7EBF0;
$black: #000;
$black-blue: #0A1929;
$blue: #007FFF;
$purple: #B570C4;
$green: #62A980;
$yellow: #FFD64B;
$orange: #FFB74D;
$dark-blue: #4A90E2;
$vivid-blue: #3399FF;
$aqua-blue: #99CCF3;
$grey-100: #CDD2D7;
$grey-200: #A0AAB4;
$grey-275: #526a7f;
$grey-250: #808D99;
$grey-300: #677C8F;
$grey-350: #434651;
$grey-400: #2D3843;
$grey-500: #1A2027;

$blue-gradient: oklch(49.07% 0.272 300.45);

// Background Colors
$bg-grey-gradient: linear-gradient(180deg, #F3F6F9 0%, #FFFFFF 100%);
$bg-dark-blue: #132F4C;
$bg-light-grey: #F3F6F9;
$bg-light-blue: #EBF4FF;
$bg-light-green:#e5f6f5;
$bg-light-red: #FFEAEC;
$bg-light-yellow: #FAF6CE;
$bg-light-purple: #F8EFFF;
$bronze: #88714D;
$lightbronze: lighten($bronze, 52%);

$coral: #FF4D58;
$burgandy: #63474D;
$gold: #FFC972;
$slateblue: #7C9EB2;
$charcoal: #585B56;

