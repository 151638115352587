
$line-height-multiplier: 1.4;
@mixin font-size-line-height($size) {
  font-size: $size;
  line-height: ($line-height-multiplier * $size);
}

::selection {
  background-color: #ffb7b7; /* WebKit/Blink Browsers */
  color: $black !important;
}
::-moz-selection {
  background-color: #ffb7b7; /* Gecko Browsers */
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}
  
body {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.4;
  letter-spacing: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

.spacer-xs {
  margin-top: 15px;
}
.spacer-sm {
  margin-top: 25px;
}
.spacer-md {
  margin-top: 35px;
}
.spacer-lg {
  margin-top: 50px;
}
.spacer-xl {
  margin-top: 75px;
}
.spacer-xxl {
  margin-top: 100px;
}

a {
  color: $black;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: lighten($black, 20%);
  }
}

.white {
  color: $white;
}




  



section {
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
  @media #{$breakpoint-md-up} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media #{$breakpoint-lg-up} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  &.center{
    text-align: center;
  }
  &.naked{
    padding: 0;
    .container {
      padding: 0;
      max-width: 100% !important;
      > div {
        padding: 60px 20px;
        @media #{$breakpoint-md-up} {
          padding: 80px 40px;
        }
        @media #{$breakpoint-lg-up} {
          padding: 100px 60px;
        }
      }
    }
  }
  &.colorful {
    .colorful-title {
      font-size: 8rem;
      margin-bottom: -30px;
      color: $white;
      transition: all 500ms;
     
      &.blue{
        animation: RedColor 4s ease-in infinite;
        animation-delay: 0s;
        transition: all 500ms;
      }
      &.red {
        // transition: all 250ms;
        // font-size: 9rem;
        // background-color: red;
        // background-image: linear-gradient(45deg, #fff86d, #f60549);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent; 
        // -moz-background-clip: text;
        // -moz-text-fill-color: transparent;
        animation: RedColor 4s ease-in infinite;
        animation-delay: 10s;
        transition: all 500ms;
      }
      &.purple {
        // transition: all 250ms;
        // font-size: 9rem;
        // background: -webkit-linear-gradient(45deg, #df52ff, #ff0011);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent; 
        // -moz-background-clip: text;
        // -moz-text-fill-color: transparent;
       animation: PurpleColor 4s ease-in infinite;
        animation-delay: 20s;
        transition: all 500ms;
      }
      &.grey {
        transition: all 250ms;
        font-size: 9rem;
        background: -webkit-linear-gradient(45deg, #eee, #333);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
      }
      
 
    }
  }
}

@keyframes PurpleColor{
  
  // 0%{
  //   transition: all 250ms;
  //       font-size: 9rem;
  //   background: white;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent; 
  //   -moz-background-clip: text;
  //   -moz-text-fill-color: transparent;
  // }
  0%{
      transition: all 250ms;
        font-size: 9rem;
        background-image: linear-gradient(45deg, #df52ff, #ff0011);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
  }
  100%{
    transition: all 250ms;
        font-size: 9rem;
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
}

@keyframes RedColor{
  // 0%{
  //   transition: all 250ms;
  //       font-size: 9rem;
  //   background: white;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent; 
  //   -moz-background-clip: text;
  //   -moz-text-fill-color: transparent;
  // }
  0%{
      transition: all 250ms;
        font-size: 9rem;
        background-image: linear-gradient(45deg, #fff86d, #f60549);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
  }
  100%{
    transition: all 250ms;
        font-size: 9rem;
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
}

.container {
  &.center {
    text-align: center;
  }
  .intro {
    font-size: 22px;
  }
}

h1 {
  // font-family: "PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  margin-bottom: 20px;
  font-size: clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem);
  font-weight: 800;
  line-height: 1.1142857142857143;
}

h2 {
  margin-bottom: 10px;
  // font-family: "PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: clamp(2.5rem, 0.9643rem + 1.4286vw, 2.25rem);
  font-weight: 800;
  line-height: 1.22222;
}

h4 {
  // font-family: $graphik-medium;
  @include font-size-line-height(1.8rem);
  @media #{$breakpoint-lg-up} {
    @include font-size-line-height(2rem);
  }
}

p {
  font-weight: 400;
  letter-spacing: 0;
  // font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  @include font-size-line-height(1.2rem);
  @media #{$breakpoint-sm-up} {
    @include font-size-line-height(1.4rem);
  }
}

// li {
//   @include font-size-line-height(1.8rem);
//   @media #{$breakpoint-md-up} {
//     @include font-size-line-height(2rem);
//   }
// }

aside, .secondary-text {
  @include font-size-line-height(1.4rem);
  @media #{$breakpoint-md-up} {
    @include font-size-line-height(1.6rem);
  }
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
}

button, .button{
  background-color: transparent;
  color: $white;
  border-radius: 40px;
  border: 2px solid $grey-350;
  padding: 1rem 1.5rem;

  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    background-color: $grey-350;
    border: 2px solid $grey-350;
    color: $white !important;
    transition: background-color 250ms;
  }
  .light-theme & {
    border: 2px solid $grey-100;
    color: $grey-350;
    &:hover {
      background-color: $grey-100;
      color: $grey-350 !important;
    }
  }
  @include font-size-line-height(1rem);
  transition: background-color 0.2s ease-in;
  transition: border 0.2s ease-in;
  @media #{$breakpoint-md-up} {
    @include font-size-line-height(1.2rem);
  }
  &.square {
    border-radius: 10px;
  }
  &.block {
    display: block;
    width: fit-content;
  }
  &.full {
    background-color: $grey-200;
    border: 2px solid $grey-200;
    transition: background-color 250ms;
    color: $black;
    &:hover {
      background-color: $grey-100;
      border: 2px solid $grey-100;
      transition: background-color 250ms;
      color: $black !important;
    }
    .light-theme & {
      border: 2px solid $grey-100;
      background-color: $grey-100;
      color: $grey-400;
      &:hover {
        background-color: $white;
      }
    }
  }

}

.center {
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
}
  



  
  

  