
//reset styles
@import "reset";
//grid from: https://github.com/drewbot/sass-flexbox-grid
@import "grid/grid-variables";
@import "grid/visibility-mixins";
@import "grid/grid-mixins";
@import "grid/grid";
@import "grid/visibility";
//theme
@import "color-variables";
@import "default-elements";
// @import "unique-styles";
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  // font-family: 'Poppins', 'elza', sans-serif;
  font-family: -apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;
  color: $grey-300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
