
@import './../css/color-variables';
.App{
  margin: 0 auto;
  background-color: rgba(#0F172B,1);

  // &.dark-theme {
  //   background-color: $black;
  //   transition: all 250ms;
  //   .line {
  //       height: 1px;
  //       border-top: 1px solid $grey-400;
  //     }
  // }
  // &.resume {
  //   background-color: rgba(#0F172B,1);
  // }
  // &.light-theme {
  //   background-color: $white;
  //   transition: all 250ms;
  //   .line {
  //       height: 1px;
  //       border-top: 1px solid $white-grey;
  //     }
  // }
  
  .noMobile {
    @media (max-width: 768px) {
        display: none;
    }
  }

  .container {
    max-width: 1290px;
    padding: 20px;
    margin: 0 auto;
    flex: 12;
    &.intro {
      p {
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin: 30px 0px;
      }
      a, button {
        // margin: 20px 0px;
      }
      .title-1 {
        font-size: 6rem;
      }
      .title-2 {
        font-size: 5rem;
      }
      .title-3 {
        font-size: 4rem;
      }
      .title-4 {
        font-size: 3rem;
      }
    }
  }

  .box-container {
    border: 1px solid $grey-350;
    border-radius: 24px;
    p {
      margin: 30px 0px;
    }
    
  }
  .padded-box {
    // padding: 60px 0px 60px 60px;
    padding: 60px;
    
  }

 

  .pricing {
    padding: 50px 30px 10px 30px;
    position: relative;
    .badge {
      position: absolute;
      background-color: white;
      color: black;
      font-weight: bold;
      padding: 10px 20px;
      top: -3%;
      left: 50%;
      transform: translate(-50%);
      border-radius: 50px;
      font-size: 14px;
      &.transparent {
        background-color: black;
        color: $grey-350;
        border: 1px solid $grey-350;
      }
    }
  }


  .margin-top {
    padding-top: 180px;
  }
  .reduce-margin {
    padding-top: 0px;
  }


  .checklist {
    margin: 30px 0px;
    li {
      font-size: 18px;
      color: $white;
      padding: 0;
      margin: 0;
      line-height: 2rem;
      font-weight: 600;
      svg {
        margin-right: 10px;
        vertical-align: middle;
        fill: $grey-350;
      }
    }
  }

}