.outer_div{
    display: flex;
    position: relative;
    height:100%;
    width:100%;
    // background-color: rgba(#0F172B,.8);
    padding-top: 75px !important;
  }
  
  .resumespacer {
    margin-top: 75px;
  }
  .section1, .section2 {
    margin-bottom: 0;
    padding-bottom: 0;
  }

.circle-chart-content {
  margin-left: 8px;
  max-width: 70px;
  text-align: center;
  font-size: 10px !important;
  font-weight: bold;
  color: rgba($color: #fff, $alpha: .7);
}

// section {
//   padding-top: 180px;
// }

.section1 {
  // padding-top: 280px;
  @media (max-width: 768px) {
    display: none;
  }
}

  .social-links {
    margin-top: 20px;
    .button {
        margin-right: 15px;
        svg {
            fill: white;
            margin-left: 20px;
        }
        &.large{
          width: 75%;
        }
        &:hover {
          background-color: rgba(255,255,255,.7);
          border-color: white;
          color: black !important;
          transition: 350ms;
          svg {
            fill: black;
          }
        }
    }
    
}

.mobile-intro{
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
  .profile_picture {
    height: 200px;
    img {
        max-height: 100%;
        border: 5px solid white;
        border-radius: 75px;
    }
  }
  .title-3 {
    font-size: 2.8em;
    margin-top: 15px;
    line-height: 1em;
    font-weight: 600;
    color: white;
}
.title-5 {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: 500;
    margin: 15px auto 15px auto;
    color: rgba(white,.75);
}
  .social-links a {
    min-width: 100%;
  }
}
  
  
  .scroll-content-left {
    display: flex;
    @media (max-width: 768px) {
      display: none;
    }
    position: sticky;
    flex: 1 0 35%;
    margin-right: 5%;
    top: 0;
    align-items: start;
    justify-content: center;
    max-width: 450px;
    .profile_picture {
      height: 200px;
      img {
          max-height: 100%;
          border: 5px solid white;
          border-radius: 75px;
      }
    }
   

    > div {
        position: sticky;
        top: 75px;
        height: auto;
        width: 100%;
      }
    .title-3 {
        font-size: 2.8em;
        line-height: 1.2em;
        font-weight: 600;
        color: white;
    }
    .title-5 {
        font-size: 1em;
        line-height: 1.2em;
        font-weight: 500;
        margin: 5px auto 15px auto;
        color: rgba(white,.75);
    }
    p.content {
        font-size: .85em;
        line-height: 1.4em;
    }
  }
  
  .anchor-link {
    margin: 20px auto;
    span {
        padding: 10px 0px;
        display: block;
        color: rgba(255,255,255,.7);
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        box-sizing: border-box;
        cursor: pointer !important;
        transition: all 250ms;
        width: fit-content;
        &::before {
            content: '';
            border-bottom: 2px solid rgba(255,255,255,.7);
            width: 25px;
            height: 2px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            transition: all 250ms;
        }
        &:hover, &.active {
            color: rgba(255,255,255,1);
            &::before {
                width: 60px;
                transition: all 250ms;
                border-bottom: 2px solid rgba(255,255,255,1);
            }
        }
    }
  }
  
  .scroll-content-right {

    position: relative;
    height:100%;
    // top: 80px;
    margin-top: 100px;
    margin-bottom: 80px;
    right:0;
    flex: 1 0 35%;
    .content {
        font-size: .9em;
    }
    h2 {
        margin-top: 30px;
    }
  
  .job-listing-container {
    padding: 10px 5px;
    margin: 20px auto;
    font-size: .8em;
    border-radius: 8px;
    transition: all 250ms;
    cursor: pointer;
    svg {
        position: relative;
        top: 5px;
        margin-left: 5px;
    }
    &:hover {
        background-color: rgba(#162446,.4);
        transition: all 250ms;
        .content h3 {
            color: #F471B5;
        }
        svg {
            transform: translate(25%,-25%);
            transition: all 250ms;
        }
    }
    .date {
        text-transform: uppercase;
        font-weight: bold;
        font-size: .75em;
        @media (max-width: 768px) {
        padding: 0;
      
        }
        > span {
            border-bottom: 1px solid #677C8F;
            width: 15px;
            margin: 0 5px;
            height: 1px;
            display: inline-block;
            vertical-align: middle;
        }
    }
    .content {
      @media (max-width: 768px) {
      padding: 0;
      padding-left: 10px;
      }
        .pill-container {
            // margin-top: 20px;
            span {
                // background-color: #162446;
                background-color: rgba(#422442,.5);
                color: #F471B5;
                text-transform: capitalize;
                padding: 5px 15px;
                margin: 5px 8px 5px 0px;
                border-radius: 25px;
                font-size: .75em;
                display: inline-block;
                font-weight: 400;
                &:first-child {
                    margin-left: 0px;
                }
                &.green{
                  background-color: rgba(#034918,.5);
                    color: #08cf47;
                }
            }
            
        }
        h3 {
            font-size: 1.2em;
            color: white;
            text-transform: capitalize;
            font-weight: 600;
            margin-bottom: 2px;
        }
        .company {
            font-size: 1em;
            color: white;
            text-transform: capitalize;
            margin-right: 10px;
            font-weight: 600;
        }
        .location {
            font-size: 1em;
            text-transform: capitalize;
            &::before {
                content: '';
                width: 5px;
                height: 5px;
                border-radius: 5px;
                background-color: #677C8F;
                display: inline-block;
                vertical-align: middle;
                margin: 0 10px 0 5px;
            }
        }
        p, div {
            font-size: .95em;
            line-height: 1.4em;
            margin: 15px auto;
            b {
                font-weight: 500;
                // color: rgba(white,.6);
                color: rgba(#F471B5,.6);
            }
            ul.list{
                // border: 1px solid red;
                margin: 10px auto;
                padding-left: 15px;
                li {
                    margin: 5px auto;
                    list-style-type: disc;
                }
            }
        }
    }
  }
}

.skills-container {
    .skills-box {
        display: inline-block;
        border: 2px solid #677C8F;
        width: 75px;
        height: 180px;
        border-radius: 20px;
        margin-right: 15px;
        text-align: center;
        padding: 25px 10px;
        h5 {
            font-weight: bold;
            font-size: 18px;
            margin: 5px auto;
        }
        p {
            font-size: 12px;
            line-height: 1.2em;
        }
        svg {

        }
    }
}

  .cursor-box {
    // width: 30em;
    // height: 30em;

    // width: 10em;
    // height: 10em;
    // position: fixed;
    // box-shadow: 0 0 0 10000px rgba(0, 0, 0, .5);
    // z-index: 0;
    // border-radius: 100%;
    .cursor-box-child {
      width: .1em;
      height: .1em;
      z-index: 0;
      position: fixed;
      border-radius: 100%;
      background-color: rgba(59,130,246,.2);
    //   border: 3;
    //   box-shadow: inset 0px 0px 200px 75px rgba(255,255,255,.5);
    // box-shadow: 0px 0px 500px 200px rgba(#1B1F25,.5);
    // box-shadow: 0px 0px 10px 200px rgba(59,130,246,.5);

    // box-shadow: 0px 0px 800px 150px rgba(59,130,246,.2);
    box-shadow: 0px 0px 800px 150px rgba(59,130,246,.1);

      .container {
        z-index: 2;
      }
    }
  }


  .insights-container{
    position: relative;
  }

   
  svg{
    font-size:0.7rem;
  }
  
  circle{
    animation: progress 2.5s ease-in-out infinite;
    /*creates a stroke the same size as the circumference of the circle*/
    // stroke-dasharray:377; 
    /*fill percentage of the stroke 377==1%*/
    // stroke-dashoffset:40; 
    stroke-linecap:round;
    transform-origin:center;
    transform:rotate(-110deg);
  }
  
  .background{
    stroke-dashoffset:0;
    stroke:hsl(0, 0%, 90%);
  }
  
  text{
    fill:hsl(0, 0%, 30%);
  }
  
  .title{
    font-size:1.5rem;
    font-weight:bold;
  }
  
  .content{
    
  }
  
  .graph-color{
    stroke: url(#MyGradient);
    animation:fill 3s ease forwards;
  }
  
  
  @keyframes fill{
    from{
      stroke-dashoffset:377;
    }
    to{
      stroke-dashoffset:40;
    }
  }
  
  
  svg:nth-of-type(2) .graph-color{
    stroke:purple;
    animation:fill-two 3s ease forwards;
  }
  
  
  @keyframes fill-two{
    from{
      stroke-dashoffset:377;
    }
    to{
      stroke-dashoffset:189;
    }
  }

  /* Circle Chart */
  .circle-chart-container {
      clear: left;
      float: left;
      position: relative;
      max-width: 165px;
      width: 100%;
      margin-left: 10px;
      &.small {
        max-width: 65px;

        .circle-chart-percentage {
            -webkit-animation: fade-in-text 2.2s 0.1s forwards;
            -moz-animation: fade-in-text 2.2s 0.1s forwards;
            animation: fade-in-text 2.2s 0.1s forwards;
            font-size: 12px;
            font-weight: 700;
            // position: absolute;
        }
        
        .circle-chart-one-percentage {
            color: #5292AC;
            left: 20px;
            top: 25px;
            @media (max-width: 378px) {
              left: 14px;
              top: 18px;
            }
        }
      }
      
  }

  .circle-chart-content {
    display: inline-block;
    font-size: 12px;
    h3 {
        font-weight: bold;
    }
  }
  
  .circle-chart {
      overflow: visible;
  }
  
  .circle-chart-circle {
       animation: circle-chart-fill 1.35s reverse;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      transform-origin: center;
  }
  
  .circle-chart-two {
      left: 6%;
      max-width: 88%;
      position: absolute;
      top: 6%;
  }
  
  .circle-chart-percentage {
      -webkit-animation: fade-in-text 2.2s 0.1s forwards;
      -moz-animation: fade-in-text 2.2s 0.1s forwards;
      animation: fade-in-text 2.2s 0.1s forwards;
      font-size: 22px;
      font-weight: 700;
      position: absolute;
  }
  
  .circle-chart-one-percentage {
      color: #5292AC;
      left: 65px;
      top: 60px;
  }
  
  .circle-chart-two-percentage {
      color: #64B2D1;
      left: 40px;
      top: 90px;
  }
  
  @keyframes circle-chart-fill {
      to { stroke-dasharray: 0 100; }
  }


/* Bar Graph Vertical */
.bar-graph .year {
    -webkit-animation: fade-in-text 2.2s 0.1s forwards;
    -moz-animation: fade-in-text 2.2s 0.1s forwards;
    animation: fade-in-text 2.2s 0.1s forwards;
  }
  
  .bar-graph-vertical {
    margin-top: 80px;
    max-width: 680px;
  }
  
  .bar-graph-vertical .bar-container {
    float: left;
    height: 150px;
    margin-right: 8px;
    position: relative;
    text-align: center;
    width: 40px;
  }
  
  .bar-graph-vertical .bar {
    border-radius: 3px;
    bottom: 40px;
    position: absolute;
    width: 40px;
  }
  
  .bar-graph-vertical .year {
    bottom: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  .bar-graph-two .bar::after {
    -webkit-animation: fade-in-text 2.2s 0.1s forwards;
    -moz-animation: fade-in-text 2.2s 0.1s forwards;
    animation: fade-in-text 2.2s 0.1s forwards;
    color: #fff;
    content: attr(data-percentage);
    font-weight: 700;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: left;
    top: 24px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  .bar-graph-two .bar-one .bar {
    background-color: #64b2d1;
    // -webkit-animation: show-bar-one-vertical 1.2s 0.1s forwards;
    // -moz-animation: show-bar-one-vertical 1.2s 0.1s forwards;
    animation-name: show-bar-one-vertical 1.2s 0.1s forwards;
  }
  
  .bar-graph-two .bar-two .bar {
    background-color: #5292ac;
    // -webkit-animation: show-bar-two-vertical 1.2s 0.2s forwards;
    // -moz-animation: show-bar-two-vertical 1.2s 0.2s forwards;
    animation-name: show-bar-two-vertical 1.2s 0.2s forwards;
  }
  
  .bar-graph-two .bar-three .bar {
    background-color: #407286;
    // -webkit-animation: show-bar-three-vertical 1.2s 0.3s forwards;
    // -moz-animation: show-bar-three-vertical 1.2s 0.3s forwards;
    animation-name: show-bar-three-vertical 1.2s 0.3s forwards;
  }
  
  .bar-graph-two .bar-four .bar {
    background-color: #2e515f;
    // -webkit-animation: show-bar-four-vertical 1.2s 0.4s forwards;
    // -moz-animation: show-bar-four-vertical 1.2s 0.4s forwards;
    animation-name: show-bar-four-vertical 1.2s 0.4s forwards;
  }
  
  /* Bar Graph Vertical Animations */
  @-webkit-keyframes show-bar-one-vertical {
    0% {
      height: 0;
    }
    100% {
      height: 40%;
    }
  }
  
  @-webkit-keyframes show-bar-two-vertical {
    0% {
      height: 0;
    }
    100% {
      height: 55%;
    }
  }
  
  @-webkit-keyframes show-bar-three-vertical {
    0% {
      height: 0;
    }
    100% {
      height: 68%;
    }
  }
  
  @-webkit-keyframes show-bar-four-vertical {
    0% {
      height: 0;
    }
    100% {
      height: 82%;
    }
  }
  
  @-webkit-keyframes fade-in-text {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  

 
  
  
  
  